import { error } from "./utils/utils.js";

export interface ChatMessage {
    role: "system" | "assistant" | "user";
    content: string;
}

export interface ChatSession {
    id: string;
    lastModified: number;
    messages: ChatMessage[];
}

export interface CompletionRequest {
    sessionId: string;
    message: string;
}

export interface JsonValue {
    [key: string]: any;
}

function apiBaseUrl() {
    if (typeof location === "undefined") return "http://localhost:3333/api/";
    return location.href.includes("localhost") || location.href.includes("192.168.1") ? `http://${location.hostname}:3333/api/` : "/api/";
}

export async function apiGet<T>(endpoint: string) {
    try {
        const result = await fetch(apiBaseUrl() + endpoint);
        if (!result.ok) throw new Error();
        return (await result.json()) as T;
    } catch (e) {
        return error(`Request /api/${endpoint} failed`, e);
    }
}

export async function apiGetBlob(endpoint: string): Promise<Blob | Error> {
    try {
        const result = await fetch(apiBaseUrl() + endpoint);
        if (!result.ok) throw new Error();
        return await result.blob();
    } catch (e) {
        return error(`Request /api/${endpoint} failed`, e);
    }
}

export async function apiGetText(endpoint: string): Promise<string | Error> {
    try {
        const result = await fetch(apiBaseUrl() + endpoint);
        if (!result.ok) throw new Error();
        return await result.text();
    } catch (e) {
        return error(`Request /api/${endpoint} failed`, e);
    }
}

export async function apiPost<T>(endpoint: string, params: URLSearchParams | FormData) {
    let headers: HeadersInit = {};
    let body: string | FormData;

    if (params instanceof URLSearchParams) {
        headers = { "Content-Type": "application/x-www-form-urlencoded" };
        body = params.toString();
    } else {
        body = params;
    }
    try {
        const result = await fetch(apiBaseUrl() + endpoint, {
            method: "POST",
            headers: headers,
            body: body,
        });
        if (!result.ok) throw new Error();
        return (await result.json()) as T;
    } catch (e) {
        return error(`Request /api/${endpoint} failed`, e);
    }
}

export function toUrlBody(params: JsonValue) {
    const urlParams = new URLSearchParams();
    for (const key in params) {
        const value = params[key];
        const type = typeof value;
        if (type == "string" || type == "number" || type == "boolean") {
            urlParams.append(key, value.toString());
        } else if (typeof value == "object") {
            urlParams.append(key, JSON.stringify(value));
        } else {
            throw new Error("Unsupported value type: " + typeof value);
        }
    }
    return urlParams;
}

export class Api {
    static async session() {
        return apiGet<ChatSession>("session");
    }

    static async complete(sessionId: string, message: string, chunkCb: (chunk: string, done: boolean) => void): Promise<boolean> {
        try {
            const response = await fetch("/api/complete", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ sessionId, message }),
            });
            if (!response.ok) return false;
            if (!response.body) return false;
            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let buffer = new Uint8Array();

            function mergeUint8Arrays(array1: Uint8Array, array2: Uint8Array): Uint8Array {
                const mergedArray = new Uint8Array(array1.length + array2.length);
                mergedArray.set(array1);
                mergedArray.set(array2, array1.length);
                return mergedArray;
            }

            async function readByte(): Promise<number | null> {
                while (buffer.length < 1) {
                    const { done, value } = await reader.read();
                    if (done) return null;
                    buffer = mergeUint8Arrays(buffer, value);
                }
                const byte = new DataView(buffer.buffer).getUint8(0);
                buffer = buffer.slice(1);
                return byte;
            }

            async function readLength(): Promise<number | null> {
                while (buffer.length < 4) {
                    const { done, value } = await reader.read();
                    if (done) return null;
                    buffer = mergeUint8Arrays(buffer, value);
                }
                const length = new DataView(buffer.buffer).getUint32(0, true);
                buffer = buffer.slice(4);
                return length;
            }

            async function readString(length: number): Promise<string | null> {
                while (buffer.length < length) {
                    const { done, value } = await reader.read();
                    if (done) return null;
                    buffer = mergeUint8Arrays(buffer, value);
                }
                const stringBytes = buffer.slice(0, length);
                buffer = buffer.slice(length);
                return decoder.decode(stringBytes);
            }

            while (true) {
                try {
                    const typeByte = await readByte();
                    const length = await readLength();
                    if (length === null) {
                        chunkCb("", true);
                        break;
                    }

                    const string = await readString(length);
                    if (string === null) {
                        chunkCb("", true);
                        break;
                    }
                    chunkCb(string, false);
                } catch (e) {
                    console.log(e);
                    return false;
                }
            }

            return true;
        } catch (e) {
            console.log(e);
            return false;
        }
    }
}
