import { PropertyValueMap, html, nothing } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { BaseElement, renderError } from "../app.js";
import { pageContainerStyle, pageContentStyle } from "../utils/styles.js";
import { Api, ChatSession } from "../api.js";
import { arrowUpDoubleIcon } from "../utils/icons.js";

@customElement("user-message")
export class UserMessage extends BaseElement {
    @property()
    role = "Du";

    @property()
    message!: string;

    render() {
        return html`<div class="flex flex-col py-2 px-4 w-full">
            <span class="font-semibold w-full">${this.role}</span>
            <div class="whitespace-pre-wrap w-full">${this.message}</div>
        </div>`;
    }
}

@customElement("assisstant-message")
export class AssistantMessage extends BaseElement {
    @property()
    session!: ChatSession;

    @property()
    query!: string;

    @query("#reply")
    reply!: HTMLDivElement;

    protected firstUpdated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
        super.firstUpdated(_changedProperties);
        this.complete();
    }

    async complete() {
        this.reply.innerText = "** Wort a bissl **";
        let first = true;
        Api.complete(this.session.id, this.query, (token: string, done: boolean) => {
            if (first) {
                first = false;
                this.reply.innerText = "";
            }
            this.reply.innerText += token;
            this.requestUpdate();
            requestAnimationFrame(() => (this.parentElement!.scrollTop = this.parentElement?.scrollHeight ?? 0));
        });
    }

    render() {
        return html`<div class="flex flex-col py-2 px-4 w-full">
            <span class="font-semibold w-full">Leitkultur-o-mat</span>
            <div id="reply" class="whitespace-pre-wrap w-full"></div>
        </div>`;
    }
}

@customElement("main-page")
export class MainPage extends BaseElement {
    @property()
    error?: string;

    @query("#messages")
    messages!: HTMLDivElement;

    @query("#query")
    query!: HTMLInputElement;

    @query("#send")
    send!: HTMLButtonElement;

    @state()
    isLoading = true;

    session?: ChatSession;

    protected firstUpdated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
        super.firstUpdated(_changedProperties);
        this.load();
    }

    async load() {
        try {
            const response = await Api.session();
            if (response instanceof Error) throw response;
            this.session = response;
            this.query.disabled = false;
        } catch (e) {
            this.error = "Hoit aus, do is a Föhler passiert";
        }
    }

    handleKeyDown(ev: KeyboardEvent) {
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            ev.stopPropagation();
            this.complete();
        }
    }

    handleInput(ev: InputEvent) {
        this.query.style.height = "auto";
        this.query.style.height = Math.min(16 * 15, this.query.scrollHeight) + "px";
        const text = this.query.value.trim();
        this.send.disabled = text.length == 0;
    }

    async complete() {
        if (!this.session) return;
        try {
            const message = this.query.value.trim();
            if (message.length == 0) return;
            this.query.value = "";
            this.send.disabled = true;
            const userMessage = new UserMessage();
            userMessage.message = message;
            userMessage.classList.add("w-full", "max-w-[640px]");
            this.messages.appendChild(userMessage);

            const assistantMessage = new AssistantMessage();
            assistantMessage.session = this.session;
            assistantMessage.query = message;
            assistantMessage.classList.add("w-full", "max-w-[640px]");
            this.messages.appendChild(assistantMessage);
            requestAnimationFrame(() => {
                this.messages.scrollTop = this.messages.scrollHeight;
            });
        } catch (e) {
        } finally {
            this.send.disabled = false;
            this.query.style.height = "";
        }
    }

    render() {
        return html`<div class="${pageContainerStyle}">
            <div class="${pageContentStyle} h-full py-4 items-center">
                <h1 class="text-center text-3xl">LEITKULTUR-O-MAT</h1>
                ${this.error ? renderError(this.error) : nothing}
                <div id="messages" class="w-full flex-grow overflow-auto flex flex-col items-center">
                    <img src="img/leitkultur.png" class="max-w-[200px] self-center" />
                    <div class="flex flex-col gap-1 text-xs text-center italic px-4 mt-4 w-full max-w-[640px]">
                        <span
                            >Gebaut mit Spucke und Tixo von <a href="https://twitter.com/badlogicgames" class="text-blue-400">Mario Zechner</a></span
                        >
                        <span
                            >Die Seite leitet deine (anonymen) Fragen an OpenAI weiter. Für OpenAI sieht es so aus, als kämen alle Fragen von
                            mir.</span
                        >
                        <span>Die Seite selbst sammelt keine Daten, nicht einmal deine IP Adresse</span>
                    </div>
                    <user-message
                        role="Leitkultur-o-mat"
                        message="Griaß di. Wos wüsstn wissn? I kann da ois über unsere schene Leitkultur erzöhn."
                        class="w-full max-w-[640px]"
                    ></user-message>
                </div>
                <div class="px-4 w-full flex justify-center">
                    <div class="flex items-center p-2 border border-divider rounded-lg w-full max-w-[640px]">
                        <textarea
                            id="query"
                            disabled
                            rows="1"
                            placeholder="Wos wülstn?"
                            @keydown=${(ev: KeyboardEvent) => this.handleKeyDown(ev)}
                            @input=${(ev: InputEvent) => this.handleInput(ev)}
                            @focus=${() =>
                                setTimeout(() => {
                                    this.messages.scrollTop = this.messages.scrollHeight;
                                }, 250)}
                            class="flex-grow resize-none outline-none"
                        ></textarea>
                        <button
                            id="send"
                            class="border border-divider rounded-lg w-8 h-8 flex justify-center items-center"
                            @click=${() => this.complete()}
                        >
                            <i class="icon w-6 h-6">${arrowUpDoubleIcon}</i>
                        </button>
                    </div>
                </div>
            </div>
        </div>`;
    }
}
